body {
  margin: 0;
  font-family: "MillerFoundrayMedium";
}

.paperContainer {
  display: flex;
  flex: 1;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.dialog-loader-2 {
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: rgba(27, 25, 25, 0.6);
  z-index: 1201;
  font-family: "MillerFoundrayMedium";
  /*max-height: fit-content;*/
}


.dialog-loader-2-hidden {
  visibility: hidden;
}

.dialog-loader-2-show {
  visibility: visible;
}

.form-bg {
  display: flex;
  min-width: 600px;
  max-width: 60%;
  justify-content: center;
  max-height: 80%;
  font-family: "MillerFoundrayMedium";
  background-color: #c7d494;
  padding: 15px;
  border-radius: 4px;
  /* min-width: 600px;
  min-height: 300px; */
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.recommendations-form-bg {
  display: flex;
  min-width: 90%;
  max-width: 90%;
  justify-content: center;
  max-height: 80%;
  font-family: "MillerFoundrayMedium";
  background-color: #c7d494;
  padding: 15px;
  border-radius: 4px;
  /* min-width: 600px;
  min-height: 300px; */
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.form-bg .form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.form-bg .form-container .body {
  background-color: #c7d494;
  overflow-y: auto;
}

/* .form-bg .form-container .body::-webkit-scrollbar {
  display: none;
} */

.form-bg .form-container .body {
  overflow-x: hidden;
}

.step001-container {
  font-family: "MillerFoundrayMedium";
  background-color: #c7d494;
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.form-bg .form-container .header h1 {
  margin: 0;
}

.form-bg .form-container .body .step001-container .MuiTextField-root {
  font-family: "MillerFoundrayMedium";
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-bg .form-container .body .step001-container .MuiBox-root {
  font-family: "MillerFoundrayMedium";
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-bg .form-container .footer button {
  margin: 4px;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  background-color: #7A9A01;
  color: #fff;
  border: none;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  font-stretch: 100%;
  font-weight: 500;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.form-bg .form-container .footer button:hover {
  font-family: "MillerFoundrayMedium";
  cursor: pointer;
  background-color: #52312d;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 35%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
}

.recommendation-form-container {
  display: flex;
  flex-direction: column;
  max-height: 80%;
  overflow: auto;
}

.trap-readings-container {
  font-family: "MillerFoundrayMedium";
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  padding: 0 24px;
  box-sizing: border-box;
}

/* TRAP INSPECTION LIST TABLE */
.trap-inspection-list-table {
  font-family: "MillerFoundrayMedium";
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;

}

.trap-inspection-list-table thead tr {
  display: table-row;
  outline: 0;
  vertical-align: middle;
  border-bottom: 0;
}

.trap-inspection-list-table thead tr th {
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  display: table-cell;
  padding: 3px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  font-weight: 400;
  line-height: 1.43;
  /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.trap-inspection-list-table tbody {
  display: table-row-group;
  font-family: "MillerFoundrayMedium";
}

.trap-inspection-list-table tbody tr {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  border-spacing: 0;
  border-collapse: collapse;
  font-family: "MillerFoundrayMedium";
}

.trap-inspection-list-table tbody tr td {
  display: table-cell;
  padding: 3px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  border-spacing: 0;
  border-collapse: collapse;
}

.scrollable-trap-readings-table-container {
  overflow: auto;
  font-family: "MillerFoundrayMedium";
  max-height: 715px;
}

.scrollable-trap-readings-table-container table {
  overflow-x: scroll;
  font-family: "MillerFoundrayMedium";
}

.sticky-table-header {
  position: sticky;
  top: 0;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #c7d494;
  z-index: 5;
  font-family: "MillerFoundrayMedium";
}

.sticky-table-footer {
  position: sticky;
  bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #c7d494;
  z-index: 5;
  font-family: "MillerFoundrayMedium";
}

.sticky-table-header-col {
  background: #c7d494;
  position: sticky;
  /* left: -105px; */
  left: 0px;
  font-family: "MillerFoundrayMedium";
}

.sticky-table-header th {
  color: #7A9A01 !important;
}

.sticky-col {
  background: #c7d494;
  position: sticky;
  z-index: 3;
  min-width: 130px !important;
  max-width: 130px !important;
  left: 0;
  font-family: "MillerFoundrayMedium";
}

.sticky-col {
  color: #7A9A01 !important;
}

.sticky-col:nth-child(2) {
  left: 130px;
  font-family: "MillerFoundrayMedium";
}

.sticky-col:nth-child(3) {
  left: 260px;
  font-family: "MillerFoundrayMedium";
}

.sticky-col:nth-child(4) {
  left: 390px;
  font-family: "MillerFoundrayMedium";
}

.week-readings-col {
  border-right: 1px solid silver;
  border-top: none;
  vertical-align: middle;
  text-align: center !important;
  font-family: "MillerFoundrayMedium";
}

.table-filter-container {
  display: flex;
  flex: 1;
  border: 1px solid blue;
  margin-bottom: 20px;
  font-family: "MillerFoundrayMedium";
}

.table-filters {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "MillerFoundrayMedium";
}




.nexus-scroll-table {
  overflow-y: auto;
  max-height: 85vh;
  font-family: "MillerFoundrayMedium";
}

.nexus-scroll-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: "MillerFoundrayMedium";
}

.nexus-scroll-table table thead th {
  position: sticky;
  top: 0;
  background: #c7d494;
  z-index: 999;
  font-family: "MillerFoundrayMedium";
  font-weight: 550;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 10px;
  color: rgb(122, 154, 1);

}

.nexus-scroll-table table tbody tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}

.nexus-scroll-table table tbody tr td {
  font-family: "MillerFoundrayMedium";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
}

.recommendation-form-fields-table {
  overflow-y: auto;
  max-height: 300px;
  font-family: "MillerFoundrayMedium";
}

.recommendation-form-fields-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: "MillerFoundrayMedium";
  overflow: auto;
}

.recommendation-form-fields-table table thead th {
  position: sticky;
  top: 0;
  background: #7A9A01;
  z-index: 999;
  font-family: "MillerFoundrayMedium";
  font-weight: 550;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 5px;
  color: #fff;
}

.recommendation-form-fields-table table tbody tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}

.recommendation-form-fields-table table tbody tr:last-child {
  background: #7A9A01;
  position: sticky;
  bottom: 0;
}

.recommendation-form-fields-table table tbody tr td {
  font-family: "MillerFoundrayMedium";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-bottom: 1px solid #7A9A01;
  text-align: left;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
}

.recommendation-form-fields-table table tbody tr:last-child td:last-child {
  color: #fff;
  font-size: 1rem;
}

.recommendation-form-intervention-table {
  overflow-y: auto;
  max-height: 300px;
  font-family: "MillerFoundrayMedium";
}

.recommendation-form-intervention-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: "MillerFoundrayMedium";
  overflow: auto;
}

.recommendation-form-intervention-table table thead th {
  position: sticky;
  top: 0;
  background: #7A9A01;
  z-index: 999;
  font-family: "MillerFoundrayMedium";
  font-weight: 550;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 5px;
  color: #fff;
}

.recommendation-form-intervention-table table tbody tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}

.recommendation-form-intervention-table table tbody tr td.placeholder {
  border: none;
}

.recommendation-form-intervention-table table tbody tr td{
  text-align: left;
  padding: 0px 5px 0px 5px;
}


.nexus-checkbox {
  display: inline-block;
  padding: 8px 8px;
  /* Force 3d rendering */
  transform: translateZ(0);
}

.nexus-checkbox label {
  cursor: pointer;
  /* Reset for Bootstrap rule */
  padding-left: 0;
}

/* Hide native checkbox */
.nexus-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}

.nexus-checkbox .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px
}

.nexus-checkbox .checkbox-material::before {
  position: absolute;
  left: 8px;
  top: 2px;
  content: "";
  background-color: #7A9A01;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
}

.nexus-checkbox .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
  color: #7A9A01;
}

.nexus-checkbox input[type=checkbox]:checked+.checkbox-material .check {
  color: #7A9A01;
}

.nexus-checkbox .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow:
    0 0 0 0,
    0 0 0 0,
    0 0 0 0,
    0 0 0 0,
    0 0 0 0,
    0 0 0 0,
    0 0 0 0 inset;
  animation: checkbox-off 0.3s forwards ease-out;
}

.nexus-checkbox input[type=checkbox]:focus+.checkbox-material .check:after {
  opacity: 0.2;
}

.nexus-checkbox input[type=checkbox]:checked+.checkbox-material .check:before {
  box-shadow:
    0 0 0 10px,
    10px -10px 0 10px,
    32px 0px 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    20px -12px 0 11px;
  animation: checkbox-on 0.3s forwards ease-out;
}

.nexus-checkbox input[type=checkbox]:not(:checked)+.checkbox-material:before {
  animation: rippleOff 700ms forwards ease-out;
}

.nexus-checkbox input[type=checkbox]:checked+.checkbox-material:before {
  animation: rippleOn 700ms forwards ease-out;
}

/* Ripple effect on click */
.nexus-checkbox input[type=checkbox]:not(:checked)+.checkbox-material .check:after {
  animation: rippleOff 700ms forwards ease-out;
}

.nexus-checkbox input[type=checkbox]:checked+.checkbox-material .check:after {
  animation: rippleOn 700ms forwards ease-out;
}

/* Animations */
@keyframes checkbox-on {
  0% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      15px 2px 0 11px;
  }

  50% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px 2px 0 11px;
  }

  100% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px -12px 0 11px;
  }
}

@keyframes checkbox-off {
  0% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px -12px 0 11px,
      0 0 0 0 inset;
  }

  25% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px -12px 0 11px,
      0 0 0 0 inset;
  }

  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0px 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      15px 2px 0 11px,
      0 0 0 0 inset;
  }

  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow:
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0px 0px 0 10px inset;
  }

  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow:
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0px 0px 0 0px inset;
  }
}

@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}

.comments-container textarea {
  font-size: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'inherit';
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  padding: 16.5px;
  border: 1px solid #7a9a01;
  box-sizing: border-box;
  font-weight: lighter;
  color: rgb(0, 0, 0);
}

.comments-container textarea:focus {
  outline: none;
}

.comments-container textarea:focus-within {
  border: 2px solid #7A9A01;
}

.scout-inspections-form-bg {
  display: flex;
  width: 95%;
  justify-content: center;
  max-height: 95%;
  font-family: "MillerFoundrayMedium";
  background-color: #c7d494;
  padding: 15px;
  border-radius: 4px;
  /* min-width: 600px;
  min-height: 300px; */
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.scout-inspections-form-bg .form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.scout-inspections-form-bg .form-container .body {
  background-color: #c7d494;
  overflow-y: auto;
}

/* .form-bg .form-container .body::-webkit-scrollbar {
  display: none;
} */

.scout-inspections-form-bgg .form-container .body {
  overflow-x: hidden;
}

.scout-inspections-form-bg .form-container .header h1 {
  margin: 0;
}

.scout-inspections-form-bg .form-container .body .step001-container .MuiTextField-root {
  font-family: "MillerFoundrayMedium";
  margin-top: 5px;
  margin-bottom: 5px;
}

.scout-inspections-form-bg .form-container .body .step001-container .MuiBox-root {
  font-family: "MillerFoundrayMedium";
  margin-top: 5px;
  margin-bottom: 5px;
}

.scout-inspections-form-bg .form-container .footer button {
  margin: 4px;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  background-color: #7A9A01;
  color: #fff;
  border: none;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  font-stretch: 100%;
  font-weight: 500;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.scout-inspections-form-bg .form-container .footer button:hover {
  font-family: "MillerFoundrayMedium";
  cursor: pointer;
  background-color: #52312d;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 35%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
}


/* SCOUT INSPECTIONS FORM TABLE */
.scout-inspection-form-table {
  font-family: "MillerFoundrayMedium";
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  margin: 30px;
}

.scout-inspection-form-table thead tr {
  display: table-row;
  outline: 0;
  vertical-align: middle;
  border-bottom: 0;
}

.scout-inspection-form-table thead tr th {
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  display: table-cell;
  padding: 10px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  line-height: 1.43;
  border: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.scout-inspection-form-table tbody {
  display: table-row-group;
  font-family: "MillerFoundrayMedium";
}

.scout-inspection-form-table tbody tr {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  border-spacing: 0;
  border-collapse: separate;
  font-family: "MillerFoundrayMedium";
}

.scout-inspection-form-table tbody tr td {
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  font-weight: 400;
  line-height: 1.43;
  border: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  border-spacing: 0;
  border-collapse: separate;
}

.scout-inspection-form-table tfoot tr th {
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "MillerFoundrayMedium";
  /* font-family: "Roboto", "Helvetica", "MillerFoundrayMedium", sans-serif; */
  border: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  border-spacing: 0;
  border-collapse: separate;
}

.scrollable-pest-inspections-table-container {
  overflow: auto;
  font-family: "MillerFoundrayMedium";
  max-height: 715px;
}

.scrollable-pest-inspections-table-container table {
  overflow-x: scroll;
  font-family: "MillerFoundrayMedium";
}

/* Inspection Form Checkbox */
.inspection-form-checkbox {
  position: relative;
}

.inspection-form-checkbox input[type="checkbox"] {
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 16px;
  line-height: 1.5;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.2s;
  accent-color: #7A9A01;
}

/* Material UI Checkbox for Inspection Form */
/* .pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 16px;
  line-height: 1.5;
} */

/* Input */
/* .pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
} */

/* Span */
/* .pure-material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
} */

/* Box */
/* .pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px;
  border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
} */

/* Checkmark */
/* .pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
} */

/* Checked, Indeterminate */
/* .pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
} */

/* .pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
} */

/* .pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
} */

/* Hover, Focus */
/* .pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox > input:focus {
  opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
} */

/* Active */
/* .pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
} */

/* Disabled */
/* .pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
} */