.body {
  background-color: #fafafa;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "MillerFoundaryMedium";
}
